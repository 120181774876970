import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa';

export default function Page(project) {
    const { id } = useParams();
    const [notes, setNotes] = useState(null);
    const editorRef = useRef(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);


    // store project
    const save = () => {
        if (editorRef.current) {
            // set header
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');

            // Build json body
            var raw = {};
            raw.status = project.status;
            raw.lastUpdateDate = Date.now();
            raw.name = project.name;
            raw.json = project;
            raw.json.notes = editorRef.current.getContent();

            delete raw.json.id;
            delete raw.json.userId;
            delete raw.json.companyId;
            delete raw.json.lastUpdateDate;
            delete raw.json.json;
            delete raw.json.creationDate;
            delete raw.json.name;

            // build request
            var requestOptions = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(raw),
                redirect: 'follow',
            };

            // send request
            const response = fetch(
                (process.env.REACT_APP_MODE === 'PROD'
                    ? process.env.REACT_APP_API_URL_PRODUCTION
                    : process.env.REACT_APP_API_URL_DEVELOPMENT) +
                '/projects/' +
                id,
                requestOptions
            ).catch((error) => console.log('error', error));
        }
    };

    const handleCopy = (inputRef) => {
        if (inputRef.current) {
            navigator.clipboard.writeText(inputRef.current.value);
            alert('URL copied!');
        }
    };

    const openInNewTab = (inputRef) => {
        if (inputRef.current && inputRef.current.value) {
            let url = inputRef.current.value;

            // Check if the URL starts with "http://" or "https://"
            if (!/^https?:\/\//i.test(url)) {
                url = `http://${url}`;
            }

            window.open(url, '_blank');
        }
    };

    return (
        <div className={'notes'}>
            <Editor
                apiKey='n9rdktq50mgdre0azy6kg134josz7ik7qwu6ixit27jsudam'
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={project?.notes ? project?.notes : ''}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [],
                    toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:12px }',
                }}
            />

            <div style={{ paddingTop: '24px', position: 'relative' }}>
                <input
                    ref={inputRef1}
                    style={{ padding: '12px', width: '100%' }}
                    placeholder={'Projekt-Link 1'}
                    value={(project?.link1 !== "") ? project?.link1  :""}
                    onChange={(e) => {
                        if (typeof project?.link1 !== 'undefined') {
                            project.link1 = e?.target?.value;
                        } else {
                            project.link1 = '';
                            project.link1 = e?.target?.value;
                        }
                    }}
                />
                {(typeof project?.link1 !== 'undefined') && (
                    <div>
                        <FaCopy
                            style={{
                                position: 'absolute',
                                right: '50px',
                                top: '40px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleCopy(inputRef1)}
                        />
                        <FaExternalLinkAlt
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '40px',
                                cursor: 'pointer',
                            }}
                            onClick={() => openInNewTab(inputRef1)}
                        />
                    </div>
                )}
            </div>

            <div style={{ paddingTop: '12px', position: 'relative' }}>
                <input
                    ref={inputRef1}
                    style={{ padding: '12px', width: '100%' }}
                    placeholder={'Projekt-Link 2'}
                    value={(project?.link1 !== "") ? project?.link1  :""}
                    onChange={(e) => {
                        if (typeof project?.link2 !== 'undefined') {
                            project.link2 = e?.target?.value;
                        } else {
                            project.link2 = '';
                            project.link2 = e?.target?.value;
                        }

                        console.log(e);
                    }}
                />
                {(typeof project?.link1 !== 'undefined') && (
                    <div>
                        <FaCopy
                            style={{
                                position: 'absolute',
                                right: '50px',
                                top: '30px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleCopy(inputRef2)}
                        />
                        <FaExternalLinkAlt
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '30px',
                                cursor: 'pointer',
                            }}
                            onClick={() => openInNewTab(inputRef2)}
                        />
                    </div>
                )}
            </div>

            <button className={'button'} onClick={save}>
                Speichern
            </button>
        </div>
    );
}

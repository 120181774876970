import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Servicegesellschaft Genossenschaften", "/project/"+id+"/drafts/"+draftId+"/documents/") }
             <div id={"page-project-documents"} className={"wrapper-content"}>

                <div className={"documentSplit"}>
                    <div className={"documentOverview "}>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/2401 Entwurf ZdK TC-Erste-Kleinstgenossenschaft_Satzung.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview11.png`} />
	                            </div>
	                            <div className={"name"}>
									Muster- / Kleinstgenossenschaft Satzung
	                            </div>
	                        </a>
	                    </div> <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Projektbeschreibung TC Erste Kleinstgenossenschaft.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview11.png`} />
	                            </div>
	                            <div className={"name"}>
									Projektbeschreibung TC Erste Kleinstgenossenschaft
	                            </div>
	                        </a>
	                    </div>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/2101 Checkliste Gründung für FP 3 Jahre.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Checkliste zur Gründungsprüfung
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/2101 Finanzplan ZdK 10 Jahre.xlsx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview14.png`} />
	                            </div>
	                            <div className={"name"}>
									Genossenschaftsgründung - Finanzplan
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a target={"_blank"}  href={`${process.env.PUBLIC_URL}/formes/Gruendungsunterlagen.pdf`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Gründungsunterlagen - Fahrplan
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Anwesenheitsliste KLEINE eG.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Anwesenheitsliste und Beteiligungserklärungen der Gründungsversammlung und ersten Generalversammlung der eG i. G.
								</div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Protokoll Gründungsversammlung KLEINE eG.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Protokoll der Gründungsversammlung und ersten Generalversammlung der eG i. G.
								</div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Protokoll_Generalversammlung_mit_AR_01.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview10.png`} />
	                            </div>
	                            <div className={"name"}>
									Musterniederschrift einer Generalversammlung
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Beitrittserklärung.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Beitrittserklärung / Zeichnung von Genossenschaftsanteilen
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Kalkulator_Oeffentliche-Mittel-V14_Baugebiet-Name.xlsx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview13.png`} />
	                            </div>
	                            <div className={"name"}>
									Projektkalkulation Bewohnergetragene Baugemeinschaft & Genossenschaft Neugründung
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a target={"_blank"}  href={`${process.env.PUBLIC_URL}/formes/478_verbindlichkeitenspiegel_beschreibbar.pdf`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview21.png`} />
	                            </div>
	                            <div className={"name"}>
									Verbindlichkeitenspiegel
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/Genossenschaften_Anzeigentext_final-2.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview22.png`} />
	                            </div>
	                            <div className={"name"}>
									Genossenschaften Anzeigentext
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/HomeFamily-TC-Kommunal-Information 2024-03-26.pptx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview23.png`} />
	                            </div>
	                            <div className={"name"}>
									HomeFamily-TC-Kommunal-Information
	                            </div>
	                        </a>
	                    </div>


	                    <div target={"_blank"}  className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/KDA0-00474_Selbstauskunft_v05_230209.pdf`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview24.png`} />
	                            </div>
	                            <div className={"name"}>
									Selbstauskunft
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a target={"_blank"} href={`${process.env.PUBLIC_URL}/formes/Prospekt Genossenschaft Home&Family Service.pdf`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview25.png`} />
	                            </div>
	                            <div className={"name"}>
									Prospekt Genossenschaft Home&Family Service
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a target={"_blank"} href={`${process.env.PUBLIC_URL}/formes/Vertriebsinfo_Projektmoderation-Genossenschaft.docx`}>
	                            <div className={"previewImage"}>
	                                <img src={`${process.env.PUBLIC_URL}/formes/preview12.png`} />
	                            </div>
	                            <div className={"name"}>
									Vertriebsanleitung zur Begleitung der Projektmoderation im genossenschaftlichen Wohnen
	                            </div>
	                        </a>
	                    </div>
					 </div>
                 </div>
			 </div>
        </div>
    );
}